/** @jsx jsx */
import { css } from "@emotion/react";

export const styles = css`
padding: 60px 0px 0px 0px;
height: auto;
background-color: #FFF;
overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 40px 0px 0px 0px;
  }

  h2 {
    color: var(--Color-Black-Kingdom, #3F3F3F);
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
    letter-spacing: -1.8px;
    margin: 0px 0px 0px 0px;
    
  }
 
`;
