/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import BgSection from "../assets/images/LP-kingdom/cta-a-escola.png";
import BgSectionMobile from "../assets/images/LP-kingdom/cctaAescola.png";

import {ctaMaisTempoParaOBem } from '../assets/styles/AboutTwo.styles'

const CtaContent = () => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500)
    }
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 500)
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  return (
    <Fragment>
      <Parallax bgImage={isMobile ? BgSectionMobile : BgSection} bgImageAlt="the cat" strength={isMobile ? 0 : 0}>
        <section css={ctaMaisTempoParaOBem}>
          <div className="div-align" />
          <div className="parallax-element"></div>
        </section>
      </Parallax>
    </Fragment>
  )
}

export default CtaContent
