/** @jsx jsx */
import { jsx } from '@emotion/react';
import { css } from '@emotion/react';
import SchoolVideo from "../assets/video/-3450-42a0-a034-6188968ce577.mp4";

const videoStyle = css`
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
`;

const schoolVideo = () => {
  return (
      <video css={videoStyle} preload="auto" controls muted>
        <source src={SchoolVideo} type="video/mp4" />
      </video>
  );
};

export default schoolVideo;