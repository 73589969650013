/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import {
  imagineUmaEscola,
  content,
  desktop,
  mobile
} from "../assets/styles/PrimeiroPasso.style";

import ImgEnsinoIntencional from "../assets/images/LP-kingdom/imagine-uma-escola.png";

const PrimeiroPasso = () => {
  return (
    <section css={imagineUmaEscola} id="imagine-uma-escola">
      <Container fluid>
        <Row className="title-content">
          <Col css={content} xs={12} lg={8} md={12} sm={12}>
            <div>
              <h2>Imagine uma escola!</h2>
              <h3>Manifesto Kingdom</h3>
              <p>Imagine um lugar que nos faz lembrar como somos únicos e que vale a pena ser quem somos! Imagine aprender de verdade, aprender com solidez, aprender a gostar de aprender!</p>
              <p>Imagine um lugar alegre, colorido, dinâmico, interessante, repleto de sorrisos, de respeito, carinho e amor! Imagine aprender se divertindo, aprender dando asas à criatividade!</p>
              <p>Imagine, também, que ensinar é compromisso e uma verdadeira paixão! Imagine que tudo nesse lugar é intencionalmente feito para que as crianças criem o futuro que elas sonharem!</p>
              <h3>Imaginou?<br /> Nós sim!<br /> Bem-vindo à Kingdom!</h3>
            </div>
          </Col>
          <Col css={desktop} className="img-container px-0" lg={4} md={12} sm={12}>
          <div
            // css={iconsBg4}
            // className="bg-image"
          >
            <img src={ImgEnsinoIntencional}></img>
          </div>
        </Col>
        </Row>
      </Container>
      <Col css={mobile} className="img-container px-0" xs={12} lg={5} md={12} sm={12}>
          <div
            // css={iconsBg4}
            // className="bg-image"
          >
            <img src={ImgEnsinoIntencional}></img>

          </div>
        </Col>
    </section>
  );
};

export default PrimeiroPasso;
