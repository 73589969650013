/** @jsx jsx */
import { css } from "@emotion/react";

export const containerCard = css`
  align-items: center;
  column-gap: 45px;
  display: grid;
  grid-template-areas: 'btnPrev slider btnRight';
  grid-template-columns: auto minmax(auto, 1200px) auto;
  margin: auto;
  padding: 0 8px;
  overflow: hidden;
  @media (max-width: 850px) {
    column-gap: 0;
    grid-template-areas:
      "slider slider slider"
      "_ btnPrev btnRight";
    padding: 0;
    row-gap: 40px;
  }
  
  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 16px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 16px)
    );
    border: 1px solid #fff !important;
    background-color: #E3E1E4;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    border: none !important;
  }
  .swiper-slide__container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #F5F5F5;
    border-radius: 56px;
    padding: 65px;
    &:hover {
      border-radius: 56px;
      background-color: #5315FF;
        -moz-transition: background-color 0.4s ease-out;
        -webkit-transition: background-color 0.4s ease-out;
        transition: background-color 0.4s ease-out;
      @media (max-width: 500px){
        border-radius: 40px;
      }
    }
    @media (max-width: 500px){
      padding: 0px;
      border-radius: 40px;
    }
  }
  .swiper-slide__circles {
    
  }
  .swiper-pagination {
    display: flex;
  }
  .swiper-wrapper {
    height: 770px;
    padding-top: 50px;
    @media (max-width: 500px) {
      height: 590px !important;
    }
  }
  .swiper {
    grid-area: slider;
    width: 150%;
    padding-right: 36px;

    &-slide {
      border-radius: 20px;
      height: 562px !important;
      max-width: 540px;
      border-radius: 56px;
      background-color: var(--Color-Black-Kingdom, #E5E5E5);
      cursor: grab;
      -moz-transition: background-color 0.4s ease-out;
      -webkit-transition: background-color 0.4s ease-out;
      transition: background-color 0.4s ease-out;
      @media (max-width: 500px) {
        max-width: 343px;
        height: 583px !important;
        border-radius: 40px;

      }
      &:hover{
        background-color:  #5315FF;
        -moz-transition: background-color 0.4s ease-out;
        -webkit-transition: background-color 0.4s ease-out;
        transition: background-color 0.4s ease-out;
      }
    

      &__container {

        
        .photoProfile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 500px){
            align-items: center;
          }
          h1{
            color: #3F3F3F;
            font-family: "montserrat", sans-serif;
            font-size: 56px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 56px */
            letter-spacing: -2.8px;
            margin-top: 16px;
            margin-bottom: 8px;
            @media (max-width: 500px) {
              margin: 10px 15px 10px 18px
            }
          }
           
         }

         .text{
          width: 100%;
          flex-shrink: 0;
          @media (max-width: 500px){
            width: 311px;
          }
          h2 {
            color: #7E7E7E;
            text-align: center;
            font-family: "montserrat", sans-serif;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 36px */
            letter-spacing: -1.5px;
            margin-bottom: 24px;
            @media (max-width: 500px){
              font-size: 30px;
              line-height: 120%; 
              letter-spacing: -1.5px;
              margin-left: 18px;
              margin-bottom: 25px;
              margin-top: 0px;
              
            }
          }
          p {
            color: #7E7E7E;
            font-family: "montserrat", sans-serif;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 187px;
            margin-bottom: 0px;
            span {
              font-weight: bold;
            }
            @media (max-width: 500px){
              margin-left: 18px;
            margin-bottom: 200px;

            }
          }
         }
          &:hover p,
          &:hover h1,
          &:hover h2{
            color: #EAECED ;
            -moz-transition: color 0.4s ease-out;
            -webkit-transition: color 0.4s ease-out;
            transition: color 0.4s ease-out;
          }

        .icon {
          border-radius: 50%;
          height: 114px;
          width: 114px;
          position: absolute;
          right: -70px;
          top: -90px;
          z-index: 999;

          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            border: 1px solid #666666;
            @media (max-width: 500px) {
              height: 90px;
              width: 90px;
            }
          }
        }

        h3 {
          color: #375f92;
          font-size: 28px;
          font-weight: 700;
          margin-bottom: 8px;
        }
        h5 {
          color: #2f382b;
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          margin-bottom: 16px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          text-align: left;
          color: #2f382b;
        }
      }

      @media (max-width: 850px) {
        &:first-of-type {
          margin-left: 42px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 37px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }

      @media (max-width: 500px) {
        &:first-of-type {
          margin-left: 20px;
        }

        margin-left: 10px;

      }
    }
  }

  .btn {
    align-items: center;
    background: rgba(237, 240, 247, 0.99);
    border: none;
    border-radius: 80px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    outline: none;
    width: 50px;

    &__icon {
      width: 100%;

      img {
        width: 100%;
      }
    }

    @media (max-width: 850px) {
      height: 36px;
      width: 36px;
    }

    @media (max-width: 500px) {
      background: inherit;
    }
  }
  .btn:hover {
    path {
      fill: #0c79fe;
    }
  }

  .slider-prev {
    grid-area: btnPrev;
    padding: 13px 20px 13px 17px;

    @media (max-width: 850px) {
      justify-self: end;
      margin-right: 40px;
      padding: 9px 14px 9px 12px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }

  .slider-next {
    grid-area: btnRight;
    justify-self: end;
    padding: 13px 17px 13px 20px;

    @media (max-width: 850px) {
      justify-self: start;
      margin-right: 58px;
      padding: 9px 12px 9px 14px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }
  .circles-container {
    display: flex;
    gap: 20px;  
    @media (max-width: 500px) {
     display: none;
    }
  }

  .circles {
    width: 16px;  
    height: 16px;
    background-color: #333; 
    border-radius: 50%; 
    @media (max-width: 500px) {
     display: none;
    }
  } 
`;