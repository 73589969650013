/** @jsx jsx */
import { css } from "@emotion/react";

import active from '../icons/Active.svg';
import defaut from '../icons/Default.svg';


export const containerKingdomMethodology = css`
    margin-top:120px;
    margin-bottom:120px;
    @media (max-width: 500px){
      margin-top:30px;
      margin-bottom:30px;
    }
 .titleApproach{
    color: #3F3F3F;
    text-align: center;
    font-family: Montserrat;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; 
    letter-spacing: -1.8px;
   @media (max-width: 576px){
      font-size: 30px;
      line-height: 120%;  
      letter-spacing: -1.5px;
   }
 } 
 .subTitle{
    color: #EA8807;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; 
    margin-top: 50px;
    margin-bottom: 150px;
 }

 span {
    color:#EA8807 ;
 }
 p{
    font-size: 16px;
    color: #3F3F3F;
    span {
       font-weight: bold;
       color: #3F3F3F;
    }
 }
  ul li {
      position: relative;
      
  }
  ul li::before {
      content: "•"; 
      color: black; 
      position: absolute;
      left: -16px; 
      top: 8;
  }
.whatsapp{
  @media (max-width: 576px){
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
 .accordion {
    --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) -(var(--bs-border-width)));
    --bs-accordion-btn-icon:  url(${defaut});
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url(${active});
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0 #F9F9F9 ;
 }
 .acordionStyleContainer{
    border-style: hidden hidden hidden hidden;
    border-radius: 32px;

 }
 .acordionStyleElement{
    border-style: hidden hidden hidden hidden;
    border-width: 2px;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-bottom-left-radius: 32px;
    background-color: #FFF;
 }
 .accordion-button{
    background-color: #F9F9F9;
    border-style: hidden hidden solid hidden;
    border-width: 1px;
    border-color: #3F3F3F;
    border-radius: 0px;
    color: #3F3F3F;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;  
    text-transform: uppercase;
 }
 .accordion-button:not(.collapsed) {
    color:  #3F3F3F;
    background-color: #FFF;
    box-shadow: none;
    border-style: hidden hidden hidden hidden;
    border-radius: 0px;
    border-color: #F9F9F9;
    color: #EA8807;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;  
    text-transform: uppercase;
 }
 .accordion .accordion-item .accordion-header .accordion-button {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
 }
 .accordion-button.collapsed {
    border-radius: 0px; 
  }
`