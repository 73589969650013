/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react'

import { containerCard } from '../assets/styles/CardSwiperHistorySchool.styles'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css'
import 'swiper/css/pagination';

import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import Ico2010 from "../assets/images/LP-kingdom/historia/2010.svg";
import Ico2011 from "../assets/images/LP-kingdom/historia/2011.svg";
import Ico2012 from "../assets/images/LP-kingdom/historia/2012.svg";
import Ico2013 from "../assets/images/LP-kingdom/historia/2013.svg";
import Ico2016 from "../assets/images/LP-kingdom/historia/2016.svg";
import Ico2017 from "../assets/images/LP-kingdom/historia/2017.svg";
import Ico2021 from "../assets/images/LP-kingdom/historia/2021.svg";
import Ico2022 from "../assets/images/LP-kingdom/historia/2022.svg";

function CardSlider() {
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 500)
      }
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth < 500)
        window.addEventListener('resize', handleResize)
      }
      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
        }
      }
    }, [])
    return (
        <div data-aos="fade-left" css={containerCard}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={isMobile ? 0 : 180}
                loop={true}
								// autoplay={false}
								arrow={true}
                autoplay={{
                    delay: 800000,
                    disableOnInteraction: false,
                  }}
                mousewheel={true}
                modules={[Autoplay, Mousewheel]}
                className='swiper'
            >
                     <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '25px'} : null} src={Ico2010} alt='foto do professor' />
                                    <h1>2010</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>A Kingdom nasce com o projeto Bebê Total.</h2>
                                </div>
                                <p style={isMobile ? {fontSize: '15px'} : null}>Nasce em Brasília o programa <span>"Bebê Total"</span>, criado para fortalecer o desenvolvimento socioemocional de crianças e pais. Associado ao processo de estimulação motora, artística e criativa, o programa oferecia atividades diárias para crianças de até 3 anos, sempre acompanhadas por seus pais, abordando diferentes formas de inteligência.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                     <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '29px'} : null} src={Ico2011} alt='foto do professor' />
                                    <h1 >2011</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>Abre o Centro Educacional Kingdom Kids.</h2>
                                </div>
                                <p style={isMobile ? {fontSize: '15px'} : null}>Com o crescimento do programa "Bebê Total" e a satisfação das famílias envolvidas, em 2011, inauguramos o centro educacional Kingdom Kids, que atendia crianças de 6 meses a 4 anos. Com uma proposta inovadora, associada aos estudos de neurociência e estimulação infantil, a Kingdom Kids logo se tornou uma referência.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '25px'} : null} src={Ico2012} alt='foto do professor' />  
                                    <h1>2012</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>Primeira expansão</h2>
                                </div>
                                <p>A repercussão positiva da forma afetuosa e intencional como nossos alunos se desenvolvem consolidou nossa presença no mercado de Brasília. Fizemos nossa primeira expansão, dobrando o número de salas.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '29px', width: '100px'} : {width: '106px'}} src={Ico2013} alt='foto do professor' />
                                    <h1>2013</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>De Centro Educacional a Escola</h2>
                                </div>
                                <p>Em 2013 fizemos uma nova expansão. Dessa vez aumentamos mais 4 salas e passamos a oferecer todas as séries da Educação Infantil. Associados ao grupo de escolas reconhecidas pela Secretaria de Educação do Distrito Federal, passamos a ofertar nossos serviços até o jardim 2.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '0px'} : null} src={Ico2016} alt='foto do professor' />
                                        <h1>2016</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2 style={isMobile ? {marginBottom: '4px',} : null}>Nova expansão e crescimento estrutural</h2>
                                </div>
                                <p  style={isMobile ? {fontSize: '15px'} : {fontSize: '15px'}}>Um novo capítulo começou para a Kingdom Kids. Em 2016, diante da crescente demanda e do nosso compromisso com o melhor acolhimento, reformulamos a recepção dos alunos. Criamos um pátio escolar e uma nova área de convivência que conectam os blocos A e B, oferecendo um espaço integrado e acolhedor.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '29px'} : null}src={Ico2017} alt='foto do professor' />
                                        <h1>2017</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>Abertura de um novo bloco</h2>
                                </div>
                                <p>Nasce o bloco C. Uma nova estrutura, dentro da escola, com mais duas salas veio para atender exclusivamente os alunos do Kids 5 (jardim II). Acreditamos na importância de uma pré alfabetização sólida e por isso um espaço apropriado e reservado é de grande importância.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '25px'} : null} src={Ico2021} alt='foto do professor' />
                                        <h1>2021</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>Abertura do Ensino Fundamental</h2>
                                </div>
                                <p style={isMobile ? null : {marginBottom:'50px'}}>A tão esperada oferta de ensino da Kingdom Kids chega para nossos alunos. Em 2021 abrimos nossa primeira turma do Ensino Fundamental.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide style={{position: 'relative'}}>
                        <div className='swiper-slide__container'>
                            <div className='photoProfile'>
                                <img style={isMobile ? {marginTop: '29px'} : null} src={Ico2022} alt='foto do professor' />
                                        <h1>2022</h1>
                            </div>

                            <div className='text'>
                                <div >
                                    <h2>Nasce a Kingdom School</h2>
                                </div>
                                <p>Um novo tempo começa aqui! A marca passa por um momento de amadurecimento e passa a ser Kingdom School. Nossos alunos do Ensino Fundamental inauguram a nova sede e passam a frequentar o novo espaço da Kingdom feito especialmente para essa etapa. Com essa expansão, a Kingdom School passa a ter duas unidades em Brasília.</p>
                            </div>
                        </div>
                        <div style={{position:'absolute', left: '565px',top: '280px'}}>
                            <div className="circles-container">
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                                <div className="circles"></div>
                            </div>
                        </div>
                    </SwiperSlide>
            </Swiper>
            <div className='swiper-pagination' />
        <div id="desafios"></div>

        </div>
    )
}

export default CardSlider