/** @jsx jsx */
import { jsx } from '@emotion/react'
import react from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { styles } from '../assets/styles/historiaEscola.styles'
import CardSlider from './schoolHistory'

const historiaEscola = () => {
  return (
    <section css={styles}>
      <Container className='mt-4' >
          <Col>
            <h2>A história da escola:</h2>
          </Col>
      </Container>
      <CardSlider />
    </section>
  )
}

export default historiaEscola