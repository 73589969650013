import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderTitle from "@/components/HeaderTitleAEscola";
import UmaEscolaAutoral from "@/components/UmaEscolaAutoralContent";
import ImagineUmaEscola from "@/components/ImagineUmaEscolaContent";
import DesenvolvimentoSegurancaAfeto from "@/components/DesenvolvimentoSegurancaAfetoContent";
import CTAAEscola from "@/components/CTAAEscola";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from '@/components/header-one';
import KingdomMethodology from '@/components/kingdomMethodology';
import SchoolHistory from '@/components/historiaEscola';
import SchoolVideo from '@/components/schoolVideo';
import SEO from '../components/seo'

const AEscolaPage = () => {
  return (
    <MenuContextProvider> 
      <SearchContextProvider>
        <Layout>
          <SEO
            title="Escola bilíngue em Brasília  | Kingdom School"
            description="A Kingdom School é referência em educação em Brasília, com ensino personalizado e bilíngue."
            keywords={
              ['kingdom school brasília',
                'escola particular brasília',
                'educação infantil brasília',
                'nsino fundamental brasília',
                'metodologia educacional',
              ]
            }
          />
          <HeaderOne />
          <div style={{ marginTop: 72 }} />
          <HeaderTitle />
          <UmaEscolaAutoral />
          <SchoolHistory/>
          <ImagineUmaEscola />
          <DesenvolvimentoSegurancaAfeto />
          <CTAAEscola />
          <KingdomMethodology />
          <SchoolVideo/>
          <Footer /> 
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AEscolaPage;
