/** @jsx jsx */
import { jsx } from "@emotion/react"
import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import learnMore from '../assets/icons/saibamais.svg'
import { containerKingdomMethodology } from "../assets/styles/kingdomMethodology.styles"

const kingdomMethodology = () => {
  return (
    <Container css={containerKingdomMethodology} id="abordagem">
			 <Row  className="justify-content-center">
          <Col sm={10} xs={12} className="text-center mb-5 pb-5">
            <h2 className="titleApproach mb-4">Abordagem atualizada, personalizada e focada no desenvolvimento completo da criança.</h2>
            <span className="subTitle">Conheça a metodologia Kingdom</span>
          </Col>
        </Row>
				<Row>
					<Col>
						<Accordion className="acordionStyleContainer">
							<Accordion.Item className="acordionStyleElement mb-5" eventKey="0">
								<Accordion.Header>ESTÍMULOS MOTORES E COGNITIVOS</Accordion.Header>
								<Accordion.Body>
									<p>
										Na Kingdom, seguimos o <span>Método Glenn Doman</span>, de estimulação a partir do nascimento, conhecido por clarear a compreensão do crescimento cerebral da criança. Essa metodologia tem demonstrado importantes ganhos ligados à performance psicomotora, que é a primeira conquista de autoconfiança da criança, habilidade essencial para o interesse e prazer pelo aprender. 
									</p>
									<p>
										Baseados no desenvolvimento cerebral das crianças, ampliamos o ganho de repertório através do uso dos cards e bits de inteligência, que são recursos aplicados pelo método e produzidos aqui na Kingdom, com o uso de diversas categorias de informações que levam as crianças a amplitude de seu conhecimento geral.
									</p>
									<p>
										Acreditamos fortemente que é fundamental suprir as demandas sensoriais, motores e sociais, ainda na primeira infância, para que possamos alcançar resultados de excelência, ao longo da trajetória acadêmica e humana das crianças.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item className="acordionStyleElement mb-5" eventKey="1">
								<Accordion.Header>DESENVOLVIMENTO PSICOMOTOR & EDUCAÇÃO FÍSICA</Accordion.Header>
								<Accordion.Body>
									<p>
										Para a Kingdom não existe saber sem o ser. O desenvolvimento motor está ligado a ganhos físicos, sociais, de ativação da aprendizagem, da curiosidade, interesse e fortalecimento de habilidade e virtudes, como disciplina e autocuidado. 
									</p>
									<p>
										<span>Na educação Infantil</span>, o aluno é exposto a três aulas por semana, realizadas em estúdios personalizados. As aulas são conduzidas por professores especializados em desenvolver habilidades como equilíbrio, coordenação motora grossa, acuidade visual, lateralidade, ritmo, fortalecimento muscular e integração sensorial.
									</p>
									<p>
										<span>No Ensino Fundamental</span>, as aulas de educação física são diárias. A ativação física, nessa faixa etária, contribui diretamente para a organização mental da criança, equilibrando suas diferentes inteligências e fortalecendo seu gosto por aprender e se desenvolver.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item className="acordionStyleElement mb-5" eventKey="2">
								<Accordion.Header>BILINGUISMO / IEYC</Accordion.Header>
								<Accordion.Body>
									<p>
									A Kingdom é uma escola brasileira que busca por meio de sua metodologia formar alunos bilíngues, na Língua Inglesa. Aqui o aluno é exposto a aulas diárias, com estrutura de aprendizado, que envolve compreensão, oralidade, leitura e escrita. Entendemos o aprendizado dessa língua como algo essencial, pois é o idioma universal que abre portas para o aluno, independente da necessidade de uso para sua carreira profissional ou para uso social.
									</p>
									<p>
										A Kingdom School adota o International Early Years Curriculum (IEYC). <span>Somos uma das 12 escolas brasileiras</span> com a licença de uso dessa metodologia, que leva o aluno a uma vivência e fluência de excelência.
									</p>
									<p>
										O IEYC faz parte do grupo Fieldwork Education, presente em mais de 90 países no mundo, com mais de 1.500 profissionais especializados envolvidos em sua construção. Esse currículo proporciona aos alunos a capacidade de se desenvolver em outra língua através de atividades eficientes e que ativam a criatividade e a conexão do aluno com seus interesses e habilidades que precisam ser desenvolvidas.
									</p>
									<p>
										Em prática com a metodologia de fluência e domínio da língua, a partir do 1° ano também adotamos os materiais da <span>Editora Cambridge</span>, para a instrução gramatical e de reforço da Língua Inglesa, nas práticas de sala de aula. Além das aulas regulares, a partir do 1° ano nossos alunos têm, diariamente, aulas de Artes e Educação Física em inglês.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
					<Col>
						<Accordion className="acordionStyleContainer">
							<Accordion.Item className="acordionStyleElement mb-5" eventKey="0">
								<Accordion.Header>EXCELÊNCIA EM PORTUGUÊS E MATEMÁTICA</Accordion.Header>
								<Accordion.Body>
									<p>
										Pensamento matemático, leitura e compreensão textual são bases para o desenvolvimento lógico-acadêmico. Nossa atenção em fortalecer o aprendizado do Português e da Matemática vem da compreensão que o domínio dessas duas áreas de conhecimento dá ao aluno a capacidade de dominar as demais disciplinas.
									</p>
									<p>
										Utilizamos do método fônico para o início do processo de alfabetização, o que leva o aluno a compreender as letras e seus fonemas e com isso entender que os diferentes sons, quando conectados, formam diferentes palavras, que formam frases e que formam textos.
									</p>
									<p>
										Na matemática, abraçamos importantes referências de ensino de Singapura, que leva o aluno a viver a matemática além do registro e das regras de cálculo. Utilizamos o princípio da <span>rota CPA - Concreto, pictórico e abstrato</span>. Cada aluno recebe desafios de raciocínio lógico e assim, as crianças experimentam a matemática não só de forma simbólica, mas vivencial. 
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item className="acordionStyleElement mb-5" eventKey="1">
								<Accordion.Header>PERSONA</Accordion.Header>
								<Accordion.Body>
									<p>
										Na Kingdom, desenvolvemos um programa socioemocional autoral que foca em três áreas principais:
									</p>
									<ul>
										<li>
											Formação do caráter
										</li>
										<li>
											Desenvolvimento de habilidades
										</li>
										<li>
											Resgate de valores e virtudes
										</li>
									</ul>
									<p>
										<strong>Persona</strong> é uma filosofia que vai além de uma simples aula ou projeto. Na prática, ensinamos a importância do respeito entre as pessoas na escola e incentivamos nossos alunos a se expressarem livremente e encontrarem um ambiente onde possam ser autênticos.
									</p>
									<p>
										Desenvolver a capacidade de tomar decisões desde a infância é essencial para que as crianças se tornem felizes e conscientes. Acreditamos nessa filosofia e a incorporamos não apenas nas aulas, mas em todo o projeto educacional.
									</p>
									<p>
										As aulas são realizadas periodicamente, e a cada módulo as crianças trabalham uma nova virtude ou habilidade. Após o desenvolvimento do módulo, aplicam-se desafios, alguns em casa e outros no ambiente escolar. Esses exercícios reforçam as habilidades e aumentam a capacidade de autoconhecimento e disciplina dos alunos.
									</p>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item className="acordionStyleElement mb-5" eventKey="2">
								<Accordion.Header>CONTRATURNO</Accordion.Header>
								<Accordion.Body>
									<p>
										O Contraturno é uma oportunidade para as famílias que querem garantir um bom aproveitamento da rotina extracurricular do filho, com práticas complementares e de relevância na formação plena.
									</p>
									<p>
										O programa oferece atividades de línguas (inglês e espanhol), práticas esportivas e pedagógicas.
									</p>
									<p>
										Os alunos que chegam antes do período regular (especificamente para alunos do vespertino) ou saem após o término dele (especificamente para alunos do matutino), podem participar das práticas do Programa Contraturno, que acontecem diariamente, nas instalações da escola.
									</p>
									<div className="whatsapp">
										<a  href="https://wa.me/5561998739556" target="_blank">
											<img src={learnMore} />
										</a>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</Col>
				</Row>
				<Row>

				</Row>
  	</Container>
  )
}

export default kingdomMethodology
