/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import { Parallax } from 'react-parallax';
import BgSection from "../assets/images/LP-kingdom/header-escola-2.png";

import { HeaderSection } from '../assets/styles/AboutTwo.styles'
import { object } from 'prop-types';
import React, { useEffect, useState } from 'react'

const headerAescola = {
  height: '400px',
  objectFit: 'cover',
  objectPosition: '-188px 21px',
  marginTop: '0px' 
}

const HeaderTitleContent = () => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500)
    }
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 500)
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  return (
    <Fragment >
      <Parallax bgImageStyle={isMobile ? headerAescola : { marginTop: '14px'} }  bgImage={BgSection} bgImageAlt="Ensino Fundamental I" strength={0}>
        <section css={HeaderSection}>
          <div className="div-align">
            <h1>A Escola</h1>
          </div>
          <div className="parallax-element"></div>
        </section>
      </Parallax>
    </Fragment>
  )
}

export default HeaderTitleContent;