/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import { Link } from "gatsby";

import {
  desenvolvimentoSegurancaAfeto,
  content,
} from "../assets/styles/AboutTwo.styles";

import ImgEscolaDePresente from "../assets/images/LP-kingdom/visao-kingdom.png";

const AboutTwo = () => {
  const [setCounter] = useState({
    startCounter: false,
  });
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={desenvolvimentoSegurancaAfeto}>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={ImgEscolaDePresente} alt="" />
    </Col>}
      <Container fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={3}
          md={3}
          sm={12}
        >
          <img src={ImgEscolaDePresente} alt="" />
        </Col>}
          <Col lg={2} md={1} sm={12} />
          <Col lg={7} md={7} sm={12}>
            <div className="content-box pl-5 ">
              <h2>Desenvolvimento pleno, segurança e afeto</h2>
              <h3>Conheça a visão Kingdom</h3>
              <p>Na Kingdom, a escola ultrapassa o conceito de centro coletivo de ensino e passa a ser um local sensível ao outro, às individualidades e potencialidades de cada um. Diante do potencial que cada pessoa carrega, aproveitamos a etapa escolar para impulsionar suas inteligências primárias e desenvolver seus demais campos de interesse.</p> 
              <p>Nosso conceito educacional segue linhas tradicionais, reconhecendo a essencialidade de processos que devem ser preservados para o melhor desenvolvimento dos alunos e atualizamos processos no que se refere a forma de levar isso aos alunos. Somos inovadores quando revisitamos e adaptamos as tradições às novas demandas, ao comportamento humano e aos processos de aprendizado.</p> 
              <p>Para compor essa estrutura, estamos comprometidos em oferecer o que chamamos de educação personalizada – que nada mais é do olhar para o aluno como pessoa e entender profundamente suas peculiaridades. Personalizar não é negociar ganhos, mas, sim, encontrar caminhos variados para alcançá-los.</p>
              <div className="btn-section">
                <Link to="/educacao-infantil#estrutura-kids">Estrutura Infantil</Link>
                <Link to="/ensino-fundamental-i#estrutura-school">Estrutura Fundamental</Link>
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
