/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from "@emotion/react";
import BgIcons3 from "../assets/images/LP-kingdom/escola-autoral-composta.png";
import { escolaAutoral } from "../assets/styles/UmaEscolaAutoralContent.styles";
import { Link } from "gatsby";

const PrimeiroPasso = () => {
  return (
    <section css={escolaAutoral}>
      <Container>
        <Row>
          <Col data-aos="fade-right" lg={6} md={6} sm={12}>
            <h2>Uma escola autoral, composta por gente especial e especializada!</h2>
            <p>A Kingdom é uma referência no setor educacional e tem orgulho de fazer história com um recurso essencial: amor e acolhimento. Estamos totalmente dedicados à formação integral dos nossos alunos, preparando-os para enfrentar o mundo real de maneira abrangente. Promovemos o desenvolvimento contínuo através de desafios diários, incentivando a busca por conhecimento, sabedoria e dignidade de forma ativa e consciente.</p>
            <p>Se você compartilha dessa visão, venha nos conhecer melhor! Já ouviu falar em ensino intencional, afetuoso e seguro? Na Kingdom, esses princípios são aplicados com base em dois pilares fundamentais: uma base educacional sólida e uma educação personalizada. Desde 2010, a Kingdom tem inovado no mercado educacional de Brasília, atualizando tradições para atender às novas demandas e às mudanças nos processos de aprendizado, da Educação Infantil ao Fundamental II.</p>
            <div className="btn-section">
              <Link to="/fale-conosco">Agende sua visita</Link>
            </div>
          </Col>
          <Col className="px-0" lg={1} md={1} sm={12} />
          <Col data-aos="fade-left"lg={5} md={5} sm={12}>
            <img className="imagemEscolaComposta" src={BgIcons3} alt="imagem" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrimeiroPasso;
